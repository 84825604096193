<template>
  <div class="home">
    <el-row :gutter="20" v-if="show.showStatistic">
      <el-col :span="8">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="月份">
              <el-date-picker size="small" :clearable="false" v-model="statistic1Param.dates" @change="resetStatistic1" type="monthrange" start-placeholder="起" end-placeholder="止" style="width: 160px;" />
            </el-form-item>
          </el-form>
          <el-row :gutter="20">
            <el-col :span="14">
              <el-statistic :precision="2" :value="statistic1Result.sendQuantity">
                <template #title>
                  <div style="display: inline-flex; align-items: center">
                    <el-text>采购数量</el-text>
                    <el-text size="small">/采购目标</el-text>
                  </div>
                </template>
                <template #suffix>
                  <el-text>{{ '/' + statistic1Result.targetProductQuantity }}</el-text>
                </template>
              </el-statistic>
            </el-col>
            <el-col :span="10">
              <el-statistic :precision="2" :value="statistic1Result.finishPercentage">
                <template #title>
                  <div style="display: inline-flex; align-items: center">
                    <el-text>完成率</el-text>
                  </div>
                </template>
                <template #suffix>
                  <el-text>%</el-text>
                </template>
              </el-statistic>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="日期">
              <el-date-picker size="small" :clearable="false" v-model="statistic2Param.date" @change="resetStatistic2" type="date" style="width: 120px;" />
            </el-form-item>
          </el-form>
          <el-statistic :precision="2" :value="statistic2Result.residueAmount">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                <el-text>采购款结余</el-text>
              </div>
            </template>
          </el-statistic>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="日期">
              <el-date-picker size="small" :clearable="false" v-model="statistic3Param.date" @change="resetStatistic3" type="date" style="width: 120px;" />
            </el-form-item>
          </el-form>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-statistic :precision="2" :value="statistic3Result.quantity">
                <template #title>
                  <div style="display: inline-flex; align-items: center">
                    <el-text>总库存量</el-text>
                  </div>
                </template>
              </el-statistic>
            </el-col>
            <el-col :span="8">
              <el-statistic :precision="2" :value="statistic3Result.productPrice">
                <template #title>
                  <div style="display: inline-flex; align-items: center">
                    <el-text>平均单价</el-text>
                  </div>
                </template>
              </el-statistic>
            </el-col>
            <el-col :span="8">
              <el-statistic :precision="2" :value="statistic3Result.transportPrice">
                <template #title>
                  <div style="display: inline-flex; align-items: center">
                    <el-text>平均运价</el-text>
                  </div>
                </template>
              </el-statistic>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="月份">
              <el-date-picker size="small" :clearable="false" v-model="statistic4Param.dates" @change="resetStatistic4" type="monthrange" start-placeholder="起" end-placeholder="止" style="width: 160px;" />
              <el-text>（上月25至本月24）</el-text>
            </el-form-item>
          </el-form>
          <el-row :gutter="20">
            <el-col :span="14">
              <el-statistic :precision="2" :value="statistic4Result.settlementQuantity">
                <template #title>
                  <div style="display: inline-flex; align-items: center">
                    <el-text>已结算</el-text>
                    <el-text size="small">/销售数量</el-text>
                  </div>
                </template>
                <template #suffix>
                  <el-text>{{ '/' + statistic4Result.saleQuantity }}</el-text>
                </template>
              </el-statistic>
            </el-col>
            <el-col :span="10">
              <el-statistic :precision="2" :value="statistic4Result.saleAmount">
                <template #title>
                  <div style="display: inline-flex; align-items: center">
                    <el-text>结算金额</el-text>
                  </div>
                </template>
              </el-statistic>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="日期">
              <el-date-picker size="small" :clearable="false" v-model="statistic5Param.date" @change="resetStatistic5" type="date" style="width: 120px;" />
            </el-form-item>
          </el-form>
          <el-statistic :precision="2" :value="statistic5Result.residueAmount">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                <el-text>应收款结余</el-text>
              </div>
            </template>
          </el-statistic>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="月份">
              <el-date-picker size="small" :clearable="false" v-model="statistic6Param.dates" @change="resetStatistic6" type="monthrange" start-placeholder="起" end-placeholder="止" style="width: 160px;" />
              <el-text>（上月25至本月24）</el-text>
            </el-form-item>
          </el-form>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-statistic :precision="2" :value="statistic6Result.saleProfitPrice">
                <template #title>
                  <div style="display: inline-flex; align-items: center">
                    <el-text>单位利润</el-text>
                  </div>
                </template>
              </el-statistic>
            </el-col>
            <el-col :span="14">
              <el-statistic :precision="2" :value="statistic6Result.taxPrice">
                <template #title>
                  <div style="display: inline-flex; align-items: center">
                    <el-text>单位税金</el-text>
                  </div>
                </template>
              </el-statistic>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="采购、销售数量">
              <el-date-picker size="small" :clearable="false" v-model="chart1Param.dates" @change="resetChart1" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 180px;" />
            </el-form-item>
          </el-form>
          <div id="chart1" style="width: 100%; height: 300px;"></div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="采购价">
              <el-date-picker size="small" :clearable="false" v-model="chart2Param.dates" @change="resetChart2" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 180px;" />
            </el-form-item>
          </el-form>
          <div id="chart2" style="width: 100%; height: 300px;"></div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="上游运费">
              <el-date-picker size="small" :clearable="false" v-model="chart3Param.dates" @change="resetChart3" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 180px;" />
            </el-form-item>
          </el-form>
          <div id="chart3" style="width: 100%; height: 300px;"></div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="chart-card" shadow="hover">
          <el-form :inline="true" style="height: 50px;">
            <el-form-item label="下游运费">
              <el-date-picker size="small" :clearable="false" v-model="chart4Param.dates" @change="resetChart4" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 180px;" />
            </el-form-item>
          </el-form>
          <div id="chart4" style="width: 100%; height: 300px;"></div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-else>
      <h1 style="text-align: center; width: 100%;">你好，{{ show.userInfo.nick }}。欢迎访问启和物流 数字化系统。</h1>
      <el-col :span="24">
        <el-empty description="您无权限查看统计数据" />
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted } from 'vue'
import math from '../../utils/math'
import api from '../../apis/api'
import * as echarts from 'echarts'
import util from '../../utils/util'

// 权限判定

const show = reactive({
  userInfo: {},
  showStatistic: false,
})

let now = new Date()
let before29 = new Date(now.getTime() - 29 * 24 * 60 * 60 * 1000)

const statistic1Param = reactive({
  dates: [now, now]
})
const statistic1Result = reactive({
  sendQuantity: 0,
  targetProductQuantity: 0,
  finishPercentage: 0,
})
const resetStatistic1 = () => {
  if (!statistic1Param.dates || statistic1Param.dates.length < 2) {
    return
  }
  api.post('/backend/statisticsChart/procureSendQuantity', {
    monthGe: util.parseTime(statistic1Param.dates[0], '{y}-{m}-{d}'),
    monthLe: util.parseTime(statistic1Param.dates[1], '{y}-{m}-{d}'),
  }).then(res => {
    statistic1Result.sendQuantity = res.sendQuantity
    statistic1Result.targetProductQuantity = res.targetProductQuantity
    statistic1Result.finishPercentage = res.finishPercentage
  })
}

const statistic2Param = reactive({
  date: now
})
const statistic2Result = reactive({
  residueAmount: 0,
})
const resetStatistic2 = () => {
  if (!statistic2Param.date) {
    return
  }
  api.post('/backend/statisticsChart/procureResidueAmount', {
    date: util.parseTime(statistic2Param.date, '{y}-{m}-{d}'),
  }).then(res => {
    statistic2Result.residueAmount = res.residueAmount
  })
}

const statistic3Param = reactive({
  date: now
})
const statistic3Result = reactive({
  quantity: 0,
  productPrice: 0,
  transportPrice: 0,
})
const resetStatistic3 = () => {
  if (!statistic3Param.date) {
    return
  }
  api.post('/backend/statisticsChart/inventoryQuantity', {
    date: util.parseTime(statistic3Param.date, '{y}-{m}-{d}'),
  }).then(res => {
    statistic3Result.quantity = res.quantity
    statistic3Result.productPrice = res.productPrice
    statistic3Result.transportPrice = res.transportPrice
  })
}

const statistic4Param = reactive({
  dates: [now, now]
})
const statistic4Result = reactive({
  settlementQuantity: 0,
  saleQuantity: 0,
  saleAmount: 0,
})
const resetStatistic4 = () => {
  if (!statistic4Param.dates || statistic4Param.dates.length < 2) {
    return
  }
  const dateGe = new Date(statistic4Param.dates[0])
  dateGe.setMonth(dateGe.getMonth() - 1)
  dateGe.setDate(25)
  const dateLe = new Date(statistic4Param.dates[1])
  dateLe.setDate(24)
  api.post('/backend/statisticsChart/saleReport', {
    dateGe: util.parseTime(dateGe, '{y}-{m}-{d}'),
    dateLe: util.parseTime(dateLe, '{y}-{m}-{d}'),
  }).then(res => {
    statistic4Result.settlementQuantity = res.settlementQuantity
    statistic4Result.saleQuantity = res.saleQuantity
    statistic4Result.saleAmount = res.saleAmount
  })
}

const statistic5Param = reactive({
  date: now
})
const statistic5Result = reactive({
  residueAmount: 0,
})
const resetStatistic5 = () => {
  if (!statistic5Param.date) {
    return
  }
  api.post('/backend/statisticsChart/saleResidueAmount', {
    date: util.parseTime(statistic5Param.date, '{y}-{m}-{d}'),
  }).then(res => {
    statistic5Result.residueAmount = res.residueAmount
  })
}

const statistic6Param = reactive({
  dates: [now, now]
})
const statistic6Result = reactive({
  saleProfitPrice: 0,
  taxPrice: 0,
})
const resetStatistic6 = () => {
  if (!statistic6Param.dates || statistic6Param.dates.length < 2) {
    return
  }
  const dateGe = new Date(statistic6Param.dates[0])
  dateGe.setMonth(dateGe.getMonth() - 1)
  dateGe.setDate(25)
  const dateLe = new Date(statistic6Param.dates[1])
  dateLe.setDate(24)
  api.post('/backend/statisticsChart/saleProfitReport', {
    dateGe: util.parseTime(dateGe, '{y}-{m}-{d}'),
    dateLe: util.parseTime(dateLe, '{y}-{m}-{d}'),
  }).then(res => {
    statistic6Result.saleProfitPrice = res.saleProfitPrice
    statistic6Result.taxPrice = res.taxPrice
  })
}

let chart1 = null
const chart1Param = reactive({
  dates: [before29, now]
})
const resetChart1 = () => {
  if (!chart1Param.dates || chart1Param.dates.length < 2) {
    return
  }
  api.post('/backend/statisticsChart/productQuantity', {
    dateGe: util.parseTime(chart1Param.dates[0], '{y}-{m}-{d}'),
    dateLe: util.parseTime(chart1Param.dates[1], '{y}-{m}-{d}'),
  }).then(res => {
    chart1 && chart1.dispose()
    chart1 = echarts.init(document.getElementById('chart1'))
    const option = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
      legend: {},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis: { type: 'category', data: res.list.map(item => item.product) },
      yAxis: { type: 'value' },
      series: [
        { name: '采购数量', type: 'bar', data: [], connectNulls: true },
        { name: '销售数量', type: 'bar', data: [], connectNulls: true }
      ]
    }
    res.list.forEach(resultItem => {
      option.series[0].data.push(resultItem.procureSendQuantity)
      option.series[1].data.push(resultItem.saleSendQuantity)
    })
    chart1.setOption(option)
  })
}

let chart2 = null
const chart2Param = reactive({
  dates: [before29, now]
})
const resetChart2 = () => {
  if (!chart1Param.dates || chart1Param.dates.length < 2) {
    return
  }
  api.post('/backend/statisticsChart/procurePriceDaily', {
    dateGe: util.parseTime(chart2Param.dates[0], '{y}-{m}-{d}'),
    dateLe: util.parseTime(chart2Param.dates[1], '{y}-{m}-{d}'),
  }).then(res => {
    const x = []
    const y = {}
    res.dateList.forEach(date => {
      x.push(util.parseTime(date, '{m}-{d}'))
      res.productList.forEach(product => {
        const item = res.list.find(item => item.date == date && item.product == product)
        if (!y[product]) {
          y[product] = []
        }
        y[product].push(item && item.procurePrice ? item.procurePrice : null)
      })
    })
    const series = []
    res.productList.forEach(product => {
      series.push({ name: product, type: 'line', data: y[product], connectNulls: true })
    })
    chart2 && chart2.dispose()
    chart2 = echarts.init(document.getElementById('chart2'))
    const option = {
      tooltip: { trigger: 'axis' },
      legend: { data: res.productList },
      grid: { left: '2%', right: '5%', bottom: '2%', containLabel: true },
      xAxis: { type: 'category', boundaryGap: false, data: x },
      yAxis: { type: 'value' },
      series: series
    }
    chart2.setOption(option)
  })
}

let chart3 = null
const chart3Param = reactive({
  dates: [before29, now]
})
const resetChart3 = () => {
  if (!chart3Param.dates || chart3Param.dates.length < 2) {
    return
  }
  api.post('/backend/statisticsChart/supplierTransportPriceDaily', {
    dateGe: util.parseTime(chart3Param.dates[0], '{y}-{m}-{d}'),
    dateLe: util.parseTime(chart3Param.dates[1], '{y}-{m}-{d}'),
  }).then(res => {
    const x = []
    const y = {}
    res.dateList.forEach(date => {
      x.push(util.parseTime(date, '{m}-{d}'))
      res.supplierList.forEach(supplier => {
        const item = res.list.find(item => item.date == date && item.supplier == supplier)
        if (!y[supplier]) {
          y[supplier] = []
        }
        y[supplier].push(item && item.transportPrice ? item.transportPrice : null)
      })
    })
    const series = []
    res.supplierList.forEach(supplier => {
      series.push({ name: supplier, type: 'line', data: y[supplier], connectNulls: true })
    })
    chart3 && chart3.dispose()
    chart3 = echarts.init(document.getElementById('chart3'))
    chart3.setOption({
      tooltip: { trigger: 'axis' },
      legend: { data: res.productNotZeroList },
      grid: { left: '2%', right: '5%', bottom: '2%', containLabel: true },
      xAxis: { type: 'category', boundaryGap: false, data: x },
      yAxis: { type: 'value', min: 100 },
      series: series
    })
  })
}

let chart4 = null
const chart4Param = reactive({
  dates: [before29, now]
})
const resetChart4 = () => {
  if (!chart4Param.dates || chart4Param.dates.length < 2) {
    return
  }
  api.post('/backend/statisticsChart/storageTransportPriceDaily', {
    dateGe: util.parseTime(chart4Param.dates[0], '{y}-{m}-{d}'),
    dateLe: util.parseTime(chart4Param.dates[1], '{y}-{m}-{d}'),
  }).then(res => {
    const x = []
    const y = {}
    res.dateList.forEach(date => {
      x.push(util.parseTime(date, '{m}-{d}'))
      res.storageList.forEach(storage => {
        const item = res.list.find(item => item.date == date && item.storage == storage)
        if (!y[storage]) {
          y[storage] = []
        }
        y[storage].push(item && item.transportPrice ? item.transportPrice : null)
      })
    })
    const series = []
    res.storageList.forEach(storage => {
      series.push({ name: storage, type: 'line', data: y[storage], connectNulls: true })
    })
    chart4 && chart4.dispose()
    chart4 = echarts.init(document.getElementById('chart4'))
    chart4.setOption({
      tooltip: { trigger: 'axis' },
      legend: { data: res.productNotZeroList },
      grid: { left: '2%', right: '5%', bottom: '2%', containLabel: true },
      xAxis: { type: 'category', boundaryGap: false, data: x },
      yAxis: { type: 'value', min: 40 },
      series: series
    })
  })
}

const funs = {
  onResizeWindow: () => {
    chart1.resize()
    chart2.resize()
    chart3.resize()
    chart4.resize()
  }
}

onMounted(() => {
  api.get('/backend/user/getMyInfo').then(res => {
    show.userInfo = res
    if (res.admin || res.role.menuList.filter(menu => menu == 'home').length > 0) {
      show.showStatistic = true
      resetStatistic1()
      resetStatistic2()
      resetStatistic3()
      resetStatistic4()
      resetStatistic5()
      resetStatistic6()
      resetChart1()
      resetChart2()
      resetChart3()
      resetChart4()
      // 监听窗口变化
      window.addEventListener('resize', funs.onResizeWindow)
    }
  })

})

onUnmounted(() => {
  if (show.showStatistic) {
    // 移除监听窗口变化
    window.removeEventListener('resize', funs.onResizeWindow)
  }
})
</script>

<style lang="less">
.home {
  .chart-card {
    margin-bottom: 20px;
  }
}
</style>